import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, DataTable } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';
import biliarySafetyTable from "../../../assets/KN-966-small-V3.svg"
import { StaticImage } from 'gatsby-plugin-image';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-966: Adverse Reactions in Patients With Advanced Biliary Tract Cancer | HCP`,
    keywords: `keynote-966, safety data`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions in patients with advanced biliary tract cancer in the KEYNOTE-966 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-biliary-tract-cancer-combination-therapy/","@type":"MedicalWebPage","name":"Safety Data from KEYNOTE⁠-⁠966","description":"Adverse reactions leading to the interruption of KEYTRUDA occurred in 55% of patients. The most common adverse reactions or laboratory abnormalities leading to interruption of KEYTRUDA (≥2%) were decreased neutrophil count (18%), decreased platelet count (10%), anemia (6%), decreased white blood count (4%), pyrexia (3.8%), fatigue (3.0%), cholangitis (2.8%), increased ALT (2.6%), increased AST (2.5%), and biliary obstruction (2.3%)."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-biliary-tract-cancer-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`
    ]
}
const biliarySafetyAlt = "Most Common Adverse Reactions to KEYTRUDA® (pembrolizumab) in KEYNOTE-966"

//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION #
const indicationId = 35;
const jobCode = jobCodes[36].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                        ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};


const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Safety Data from KEYNOTE&#8288;-&#8288;966' }
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>KEYTRUDA was discontinued for adverse reactions in 15% of 529 patients. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA (≥1%) was pneumonitis (1.3%).</p>
                                <p>Adverse reactions leading to the interruption of KEYTRUDA occurred in 55% of patients. The most common adverse reactions or laboratory abnormalities leading to interruption of KEYTRUDA (≥2%) were decreased neutrophil count (18%), decreased platelet count (10%), anemia (6%), decreased white blood count (4%), pyrexia (3.8%), fatigue (3.0%), cholangitis (2.8%), increased ALT (2.6%), increased AST (2.5%), and biliary obstruction (2.3%).</p>
                            </TextBlock>
                            <DataTable 
                                mobileImage={() => <img src={biliarySafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={biliarySafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/biliary-KN-966-v4.png" alt={biliarySafetyAlt}/>}
                                title="Adverse Reactions With a Difference of ≥ 5% in the KEYTRUDA + Gemcitabine/Cisplatin Arm"
                                definitions="Q3W = every 3 weeks."
                                additionalInformation={[
                                    {
                                        text: `<p>There were no clinically meaningful differences in incidence of Grade 3&ndash;4 toxicity between arms.</p>`
                                    },

                                ]}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
